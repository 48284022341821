<template>
  <div>
    <div class="sctp-container sctp-pad-tb20 container clear">
      <div class="" style="margin-top: -10px;">
        <el-breadcrumb separator=">">
          <el-breadcrumb-item>首页</el-breadcrumb-item>
          <el-breadcrumb-item>文档中心</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <div class="sctp-bg-white sctp-pad-tb10 sctp-mar-tb10">
        <condition
            @select="onConditionSelect"
            :data="condition"
            :value="conditionDefaultValue"
        ></condition>
      </div>
      <el-row :gutter="10">
        <el-col :span="18">
          <el-row>
            <el-col :span="24">
              <div class="flex flex-sb sctp-bg-white" style="padding: 8px;">
                <div class="flex">
                  <el-button-group>
                    <el-button size="mini" @click="orderByClick('')">综合
                    </el-button>
                    <el-button size="mini"
                               @click="orderByClick('createtime')"
                    >发布时间
                      {{
                        orderByStatus('createtime') === 0 ? '' : orderByStatus('createtime') > 0
                            ? '▲' : '▼'
                      }}
                    </el-button>
                    <el-button size="mini"
                               @click="orderByClick('saleprice')"
                    >价格
                      {{
                        orderByStatus('saleprice') === 0 ? '' : orderByStatus('saleprice') > 0
                            ? '▲' : '▼'
                      }}
                    </el-button>
                  </el-button-group>
                  <div class="price-input-container">
                    <el-input size="mini" type="number" v-model="doc.searchForm.minPrice" @change="blur1">
                      <div slot="prefix">¥</div>
                    </el-input>
                    ~
                    <el-input size="mini" type="number" v-model="doc.searchForm.maxPrice" @change="blur2">
                      <div slot="prefix">¥</div>
                    </el-input>
                    <el-button class="mg-l5" @click="doc.loadData" size="mini">确认</el-button>
                  </div>
                </div>
                <el-input size="mini" v-model="doc.searchForm.title" placeholder="文档标题"
                          :style="{width:'auto'}">
                  <el-button slot="append"
                             @click="doc.loadData"
                             icon="el-icon-search"></el-button>
                </el-input>
              </div>
            </el-col>
          </el-row>
          <el-row :gutter="10">
            <template v-for="document in doc.data">
              <el-col :span="8">
                <a :href="`/techdocs/view/${document.productId}`" target="_blank">
                  <div class="sctp-bg-white sctp-flex sctp-flex-column sctp-mar-t10">
                    <el-image
                        style="width: 100%;height: 200px;"
                        :src="document.productLogo" fit="cover"
                    ></el-image>
                    <div class="" style="padding: 0 8px 8px;">
                      <div class="sctp-ellipsis sctp-mar-tb5">{{ document.productName }}</div>
                      <div class="flex flex-sb fz-12 fc-info">
                        <div>
                          <span>{{ document.subTypeName }}</span>
                        </div>
                        <div>
                          <span>{{ getCity(document.cityCode) }}</span>
                        </div>
                      </div>
                      <div class="divider"></div>
                      <div class="sctp-flex sctp-flex-sb sctp-mar-tb5">
                        <div class="sctp-red">{{
                            document.productPrice ? `¥${document.productPrice}` : '免费'
                          }}
                        </div>
                        <div class="">浏览数：{{ document.hits || 0 }}</div>
                      </div>
                    </div>
                  </div>
                </a>
              </el-col>
            </template>
          </el-row>
          <div class="sctp-pad-tb10 sctp-flex sctp-flex-fe sctp-bg-white sctp-mar-t10">
            <el-pagination
                :current-page="doc.pageConfig.page"
                :page-size="doc.pageConfig.limit"
                @size-change="doc.pageConfig.handleSizeChange"
                @current-change="doc.pageConfig.handleCurrentChange"
                :page-sizes="doc.pageConfig.pageSizes"
                layout="prev, pager, next, total, sizes"
                :total="doc.pageConfig.total"
            ></el-pagination>
          </div>
        </el-col>
        <el-col :span="6" class="">
          <div class="sctp-flex sctp-flex-sb sctp-pad-tb15 sctp-bg-white">
            <div class="slider-card-title">推荐文档</div>
            <div></div>
          </div>
          <div class="sctp-bg-white">
            <template v-for="(recommendItem,index) in doc.recommendDoc">
              <a target="_blank" :href="'/techdocs/view/' + recommendItem.productId"
                 :key="recommendItem.productId">
                <div class="">
                  <div class="sctp-flex sctp-wfull sctp-pad5">
                    <el-image
                        class="sctp-mar-r10"
                        :src="recommendItem.productLogo"
                        fit="cover"
                        style="width:100px;height:100px;"
                    ></el-image>
                    <div class="sctp-flex-item-1">
                      <div class="flex flex-sb flex-col full">
                        <div class="sctp-ellipsis--l3">{{ recommendItem.productName }}</div>
                        <div class="sctp-mar-t5"><span>价格：</span><span class="sctp-red">{{
                            recommendItem.productPrice ? `¥${recommendItem.productPrice}` : '免费'
                          }}</span></div>
                      </div>
                    </div>
                  </div>
                  <template v-if="index !== doc.recommendDoc.length - 1">
                    <div class="divider"></div>
                  </template>
                </div>
              </a>
            </template>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import {doc} from "@/apis/index";
import {common} from "@/apis";
import area from "@/resources/js/area";

export default {
  name: "center",
  components: {
    condition: () => import('@PAGE/home/QueryMenu.vue')
  },
  data() {
    return {
      doc: {
        searchForm: {
          title: null,
          orderBy: null,
          codeType: null,
          docType: null,
          docSubType: null,
          minPrice: null,
          maxPrice: null,
        },
        condition: {
          codeType: [],
          docTypes: [],
          types: [],
          subTypes: [],
        },
        loadCondition: () => {
          doc.loadCondition().then(res => {
            let {codeType, docTypes, types, subTypes} = res.retdata;
            this.doc.condition.codeType = codeType;
            this.doc.condition.docTypes = docTypes;
            this.doc.condition.types = types;
            this.doc.condition.subTypes = subTypes;
          });
        },
        data: [],
        recommendDoc: [],
        loadData: () => {
          let {pageConfig: {page, limit}, searchForm} = this.doc;
          doc.center({
            page: page,
            limit: limit,
            docName: searchForm.title,
            orderBy: searchForm.orderBy,
            docType: searchForm.docType,
            docSubType: searchForm.docSubType,
            priceMax: searchForm.maxPrice,
            priceMin: searchForm.minPrice,
          }).then(res => {
            console.log(res)
            let {retdata = [], count = 0, recommendDoc = []} = res;
            this.doc.data = retdata;
            // console.log(data)
            this.doc.recommendDoc = recommendDoc;
            // console.log(recommendDoc.length)
            this.doc.pageConfig.total = count;
          })
        },
        pageConfig: {
          page: 1,
          limit: 12,
          total: 0,
          pageSizes: [1, 2, 5, 10].map(item => item * 12),
          handleSizeChange: (limit) => {
            this.doc.pageConfig.page = 1;
            this.doc.pageConfig.limit = limit;
            this.doc.loadData();
          },
          handleCurrentChange: (page) => {
            this.doc.pageConfig.page = page;
            this.doc.loadData();
          }
        },
      },
    }
  },
  watch: {
    '$route'(to, from) {
      if (to.path === from.path) {
        this.loadRouterData();
        this.doc.loadData();
      }
    }
  },
  methods: {
    blur1(){
      if(this.doc.searchForm.minPrice!=null) {

        if(!(/^(([^0][0-9]+|0)\.([0-9]{1,2})$)|^(([^0][0-9]+|0)$)|^(([1-9]+)\.([0-9]{1,2})$)|^(([1-9]+)$)/).test(this.doc.searchForm.minPrice))
        {
          this.$alert('输入错误！价格精确到2位正小数且不能包含字母')
          this.doc.searchForm.minPrice=0
          return
        }
        let a = this.doc.searchForm.maxPrice * 1;
        let b = this.doc.searchForm.minPrice * 1;

        if(b<0)
        {
          this.$alert('价格不能为负数')
          this.doc.searchForm.minPrice=0
          return
        }
        if(a<b&&this.doc.searchForm.maxPrice!=null)
        {
          this.$alert("最低价格不能超过最高价格")
          this.doc.searchForm.minPrice=0
        }
      }
    },
    blur2(){
      if(this.doc.searchForm.maxPrice!=null) {
        if(!(/^(([^0][0-9]+|0)\.([0-9]{1,2})$)|^(([^0][0-9]+|0)$)|^(([1-9]+)\.([0-9]{1,2})$)|^(([1-9]+)$)/).test(this.doc.searchForm.maxPrice))
        {
          this.$alert('输入错误！价格精确到2位正小数且不能包含字母')
          this.doc.searchForm.maxPrice=0
          return
        }

        let a = this.doc.searchForm.maxPrice * 1;
        if(a<0)
        {
          this.$alert('价格不能为负数')
          this.doc.searchForm.maxPrice=0
          return
        }
        let b = this.doc.searchForm.minPrice * 1;
        if(a<b&&this.doc.searchForm.minPrice!=null)
        {
          this.$alert("最高价格不能低于最低价格")
          this.doc.searchForm.maxPrice=0
        }
      }
    },
    orderByClick(field) {
      let result = null;
      if (field) {
        let ret = this.orderByStatus(field);
        if (ret === 0) {
          result = {field: field, orderByType: 'ASC'};
        } else if (ret === 1) {
          result = {field: field, orderByType: 'DESC'};
        } else if (ret === -1) {
          result = null;
        }
      }
      this.doc.searchForm.orderBy = result;
      this.doc.loadData();
    },
    onConditionSelect({label, target}) {
      const {value} = target;
      switch (label) {
        case '文档一级分类':
          this.doc.searchForm.docType = value;
          this.doc.searchForm.docSubType = null;
          this.doc.loadData();
          break;
        case '文档二级分类':
          this.doc.searchForm.docSubType = value;
          this.doc.loadData();
          break;
        default:
          break
      }
    },
    loadRouterData() {
      let {search} = this.getQuery();
      this.doc.searchForm.title = search || null;
    },
  },
  computed: {
    orderByStatus() {
      return function (field) {
        let orderBy = this.doc.searchForm.orderBy;
        if (orderBy && orderBy.field && orderBy.field === field) {
          if (!orderBy.orderByType || orderBy.orderByType === 'ASC') {
            return 1;
          }
          return -1;
        }
        return 0;
      }
    },
    conditionDefaultValue() {
      return {
        文档一级分类: null,
        文档二级分类: null,
      };
    },
    condition() {
      let defaultItem = [{
        title: '全部',
        value: null,
      }]
      return [{
        label: '文档一级分类',
        values: defaultItem.concat(this.doc.condition.types
        .map(item => {
          item.title = item.typeName;
          item.value = item.typeId;
          return item
        }))
      }, {
        label: '文档二级分类',
        values: defaultItem.concat(this.doc.condition.types
        .filter(item => !this.doc.searchForm.docType || this.doc.searchForm.docType === item.typeId)
        .reduce((previousValue, currentValue) => previousValue.concat(currentValue.children), [])
        .map(item => {
          item.title = item.typeName;
          item.value = item.typeId;
          return item
        }))
      }];
    },
    getCity() {
      return function (code) {
        return area.parseCity(code).name;
      }
    }
  },
  mounted() {
    common.viewHistory({
      userId: (this.user && this.user.userId) || null,
      id: 11,
      title: '技术文档大厅',
      type: 11
    });
  },
  beforeMount() {
    this.loadRouterData();
    this.doc.loadData();
    this.doc.loadCondition();
  }
}
</script>

<style scoped lang="scss">
.price-input-container {
  ::v-deep {
    .el-input {
      width: 60px;
    }

    .el-input__inner {
      padding: 0 0 0 20px;
    }

    .el-input__prefix {
      div {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}

.divider {
  height: 1px;
  border-bottom: #dddddd 1px dashed;
  margin: 5px 0;
  width: 100%;
}
</style>
